<template>
	<page-container class="index" :page="page">
		<heading-image>
			<hero-image v-if="page.headingImage" type="fullscreen">
				<div v-if="defaults[locale].homepage.headingImageTitle" class="hero-content">
					<p>
						<span>{{ defaults[locale].homepage.headingImageTitle }}</span>
					</p>
					<div v-parse-links v-html="defaults[locale].homepage.headingImageContent ?? ''" />
				</div>
				<div class="slider-overlay" />
				<picture>
					<source
						v-if="page.headingImagePortraitWebp"
						:srcset="page.headingImagePortraitWebp"
						media="(max-width: 500px)"
						type="image/webp"
					/>
					<source
						v-if="page.headingImagePortrait"
						:srcset="page.headingImagePortrait"
						media="(max-width: 500px)"
					/>
					<source :srcset="page.headingImageWebp" type="image/webp" />
					<source :srcset="page.headingImage" />
					<img class="object-fit" :src="page.headingImage" :alt="page.headingImageAlt" />
				</picture>
			</hero-image>

			<booking-form :booking-form="defaults[locale].bookingForm" />
		</heading-image>

		<main-content :title="page.title || page.header" :content="page.content" />
		<section v-if="defaults[locale].homepage.benefit.length" class="bookingbenefits">
			<div class="benefits-wrapper row">
				<div class="row">
					<div v-for="benefit in defaults[locale].homepage.benefit" :key="benefit.ID" class="columns column3">
						<div class="icon" />
						<div v-parse-links v-html="benefit.content" />
					</div>
				</div>
				<div class="row">
					<div class="columns column12 align-center">
						<book-button class="button book-now cta">
							<span>{{ $t('buttonMakeReservation') }}</span>
						</book-button>
					</div>
				</div>
			</div>
		</section>

		<promoblocks :items="page.promoblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionPromotionTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionPromotionContent" />
					</div>
				</div>
			</template>
		</promoblocks>

		<contentblocks :items="page.contentblocks">
			<template #blocks-header>
				<div class="row blocks-header align-center">
					<div class="columns column12">
						<h2>{{ defaults[locale].homepage.sectionContentblocksTitle }}</h2>
						<div v-parse-links v-html="defaults[locale].homepage.sectionContentblocksContent" />
					</div>
				</div>
			</template>
		</contentblocks>

		<frequently-asked-questions
			v-if="page.frequentlyAskedQuestion"
			:items="page.frequentlyAskedQuestion.faqitems"
			:header="page.frequentlyAskedQuestion.header"
		/>
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();

if (!defaults.value) {
	await fetchDefaults();
}
</script>

<style lang="scss" scoped>
.slider-overlay {
	position: absolute;
	inset: 0;
	z-index: 2;
	background: rgb(0 0 0 / 25%);
}

.intro .column8 {
	padding: 20px 60px;
}

.bookingbenefits {
	background: var(--primary-background-color);
	z-index: 1;

	.benefits-wrapper {
		border: 10px solid var(--cta-color);
		padding: 100px 30px 50px;
		margin-top: -260px;

		.row {
			width: 100%;
		}
	}

	:deep(h2),
	:deep(h3) {
		font-size: 20px;
	}

	.book-now {
		margin: 40px auto 0;
	}

	.column3 {
		text-align: center;

		.icon {
			width: 80px;
			height: 80px;
			margin: 0 auto 40px;
			background: url('~/assets/images/icon-benefits-air-conditioner.png') no-repeat center center;
			background-size: 80px;
		}

		&:nth-child(2) {
			.icon {
				background: url('~/assets/images/icon-benefits-tv.png') no-repeat center center;
				background-size: 80px;
			}
		}

		&:nth-child(3) {
			.icon {
				background: url('~/assets/images/icon-benefits-wifi.png') no-repeat center center;
				background-size: 80px;
			}
		}

		&:nth-child(4) {
			.icon {
				background: url('~/assets/images/icon-benefits-balcony.png') no-repeat center center;
				background-size: 80px;
			}
		}
	}
}

.blocks-header {
	.column12 {
		flex-flow: column wrap;
	}
}

.hero-content {
	position: absolute;
	max-width: 1400px;
	z-index: 10;
	left: 0;
	right: 0;
	width: auto;
	bottom: 50%;
	margin: 0 auto -70px;
	text-align: center;
	color: #fff;
	letter-spacing: 2px;

	:deep(span) {
		color: #fff;
		font-size: 2em;
		font-family: var(--heading-font-family);
		font-weight: 700;
		margin: 0 0 10px;
		width: 100%;
		display: inline-block;
		line-height: 1;
	}

	:deep(p) {
		font-size: 1.5em;
	}
}

@media (max-width: 880px) {
	.hero-content {
		:deep(span) {
			font-size: 2rem;
		}

		:deep(p) {
			font-size: 1.4em;
			padding: 0 10px;
		}
	}

	.bookingbenefits {
		.benefits-wrapper {
			margin-top: -240px;
			padding: 175px 25px 40px;
		}
	}
}
</style>
