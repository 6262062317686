<template>
	<section ref="qrPanel" class="qr-section">
		<client-only>
			<div class="qr-panel">
				<div class="row">
					<div class="booking book-form">
						<div class="columns column3">
							<date-picker
								v-model:value="state.arrival"
								:clearable="false"
								:disabled-date="disableArrivalDates"
								value-type="MM-DD-YYYY"
								format="DD-MM-YYYY"
								:placeholder="$t('checkIn')"
								:lang="datepickerLang"
								@pick="pickArrival"
							/>
						</div>
						<div class="columns column3">
							<date-picker
								v-model:value="state.departure"
								:open="state.openDeparture"
								:disabled-date="disableDepartureDates"
								value-type="MM-DD-YYYY"
								format="DD-MM-YYYY"
								:placeholder="$t('checkOut')"
								:lang="datepickerLang"
								@open="state.openDeparture = true"
								@close="state.openDeparture = false"
							/>
						</div>
						<div class="columns column3">
							<input
								type="text"
								class="promo-code"
								:placeholder="bookingForm.promoCode"
								:value="state.promoCode"
							/>
						</div>
						<div class="columns column3">
							<book-button
								class="button book-now cta qr"
								:arrival="state.arrival"
								:departure="state.departure"
								:promo="state.promoCode"
							>
								{{ bookingForm.availability }}
							</book-button>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="columns column12">
						<span>
							<strong>{{ bookingForm.benefitsContent }}</strong>
						</span>
						<span v-for="benefit in bookingForm.benefit" :key="benefit.ID">
							<font-awesome-icon :icon="`fa fa-check`" size="1x" />
							{{ benefit.content }}
						</span>
					</div>
				</div>
			</div>
		</client-only>
	</section>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { locale } = useI18n();

defineProps({
	bookingForm: { type: Object, default: () => ({}) },
});

const state = reactive({
	arrival: null,
	departure: null,
	promoCode: null,
	openDeparture: false,
	pickArrivalDate: null,
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style>
.mx-datepicker {
	width: 100%;
}

.mx-input,
.qr-panel .book-form input,
.qr-panel .book-form select {
	margin: 3px 0 !important;
	width: 100% !important;
	padding: 15px 40px 15px 15px !important;
	height: 60px !important;
	border: none !important;
}
</style>

<style lang="scss" scoped>
.qr-section {
	text-align: center;
	margin: 0 auto;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0;
	float: left;
	width: 100%;
	max-width: 1060px;
	z-index: 10;
	box-shadow: (0 4px 20px rgba(0 0 0 / 15%));
}

.qr-panel {
	float: none;
	width: 100%;
	margin: 0 auto;
	display: inline-block;
	position: relative;
	z-index: 2;
	text-align: center;

	.row {
		width: 100%;
		background: #f7f6f3;
		padding: 10px 15px;

		&:last-child {
			background: #fff;
			font-size: 14px;

			.column12 {
				text-align: left;
			}

			span {
				margin: 0 30px 0 0;

				svg {
					color: var(--cta-color);
					margin: 0 5px 0 0;
				}
			}
		}
	}

	.columns {
		padding: 10px;
	}

	.book-form {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;

		input {
			font-weight: 700;
			color: var(--heading-color);
			font-family: var(--heading-font-family);
			font-size: 18px;
			border: none;

			&::placeholder {
				font-weight: 700;
				color: var(--heading-color);
			}

			&.promo-code {
				background: url('~/assets/images/qr-icon-tag.png') no-repeat center right 10px #fff;
				background-size: 21px;
			}

			&.mx-input {
				font-weight: 700;
				color: var(--heading-color);

				&::placeholder {
					font-weight: 700;
					color: var(--heading-color);
				}
			}
		}

		button.qr {
			height: 60px;
			width: 100%;
			text-transform: uppercase;
			border: none;
		}
	}
}
</style>
